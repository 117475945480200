.s1 {
  height: 65px;
  padding: 0 30px;
}

.s2 {
  width: calc(100% - 30px);
  z-index: 999;
}

.s3 {
  height: 430px;
}

.s4 {
  object-fit: cover;
}

.s5 {
  max-width: 700px;
}

.s6 {
  height: 200px;
}

.p30 {
  padding: 30px;
}

.s7 {
  height: 300px;
}

.s8 {
  width: 100px;
  height: 100px;
}

.s9 {
  border-color: rgba(256, 256, 256, .1) !important;
}

.h100 {
  min-height: 100vh;
}

.w-lg-50 {
  width: 48% !important;
}

.mt---0 {
  margin-top: -5px !important;
}

.z-1 {
  z-index: 2;
}

.img-res {
  height: 120px;
}

.img-res img {
  max-height: 120px !important;
}

.p2 {
  width: 60px !important;
}

.navbar-brand-img {
  width: 90px;
}

.h50 {
  min-height: 50vh;
}

.rounded-start {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.mycart {
  display: none !important;
}

.bg-ok,
.bg-ok input,
.bg-ok label {
  cursor: pointer;
  /* background: rgb(255, 255, 255, .4) !important; */
}

.bg-ok {
  /* border: 1px solid grey; */
  /* border-radius: 5px; */
  /* padding: 10px; */
}

.bg-ok:hover {
  border: 1px solid grey;
  border-radius: 5px;
}

.hover:hover {
  background-color: lightgrey;
}

.checkmark {
  color: #9ABC66;
  font-size: 100px;
  line-height: 200px;
  margin-left:-15px;
}



@media only screen and (max-width:768px) {
  .w-lg-50 {
    width: 100% !important;
  }

  .mycart {
    display: block !important;
    position: fixed !important;
    bottom: 10px;
    right: 10px;
  }

  .w100 {
    width: 100%;
    /* margin-bottom: 10px; */
    /* display: none; */
  }

  .h50 {
    min-height: auto;
  }
}

@media only screen and (max-width: 576px) {}